import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { DateTime } from "luxon";
import { convertImageToBase64 } from "./convertImageToBase64";

export const printInvoice = async ({ invoiceId, fetch, t }) => {
    const endpoints = [
        {
            endpoint: "settings",
            responseData: `_id email phone social_accounts{network account} logo name address`,
        },
        {
            endpoint: "invoice",
            responseData: `_id patient_id patient_name patient_lastname patient_phone patient_email username table_data{treatment price} discount total created_at`,
            data: {
                _id: invoiceId,
            },
        },
    ];
    const response = await fetch({
        operation: "query",
        multipleEndpoints: endpoints,
    });
    let treatments = [];
    if (response?.invoice?.patient_id) {
        const patientTreatments = await fetch({
            operation: "query",
            endpoint: "patient",
            data: { _id: response?.invoice?.patient_id },
            responseData: "treatments{_id service_name price}",
        });
        if (patientTreatments?.patient?.treatments) treatments = patientTreatments?.patient?.treatments;
    }
    const { email = "", phone = "", social_accounts, name = "", address = "", logo } = response?.settings || {};
    const {
        patient_name = "",
        patient_lastname = "",
        patient_phone = "",
        patient_email = "",
        username,
        total,
        discount,
        created_at,
    } = response?.invoice || {};
    let { table_data } = response?.invoice || {};
    table_data = table_data.map((td) => {
        const serviceName = treatments.find((treatment) => treatment._id === td.treatment)?.service_name;
        return {
            ...td,
            treatmentName: serviceName || "",
        };
    });
    const pdfLogo = !!logo
        ? logo
        : "https://scontent.ftia4-1.fna.fbcdn.net/v/t1.6435-9/191778528_182789857069137_3740446214998883965_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=e3f864&_nc_ohc=VuUUY-bSrtQAX9e5uNB&_nc_ht=scontent.ftia4-1.fna&oh=00_AfBRwsUNRCl0cJuA_Mlbw019z39iiE7VI-dl9OmC3x_gYA&oe=6443F60E";
    const base64Image = await convertImageToBase64(pdfLogo);
    const doc = new jsPDF("p", "mm", "a4");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    let row = 20;
    doc.text(name, 15, row);
    if (base64Image) {
        doc.addImage(base64Image, "png", pageWidth - 70, row - 10, 60, 30);
    }
    row += 10;
    doc.setFontSize(15);
    doc.text(address || "", 15, row);

    autoTable(doc, {
        head: [
            [
                t("Patient"),
                t("Clinic"),
                t("Date"),
                !!created_at ? DateTime.fromISO(created_at).toFormat("yyyy LLL dd HH:mm") : "",
            ],
        ],
        body: [
            [patient_name + " " + patient_lastname, name],
            [patient_email, email],
            [patient_phone, phone],
        ],
        theme: "plain",
        margin: { top: 40 },
    });
    table_data = table_data?.map((data) => [data?.treatmentName, parseFloat(data?.price).toFixed(2)]);

    const body = table_data.concat([
        [
            { content: t("Discount"), styles: { fontStyle: "bold", fillColor: "white" } },
            { content: discount || "", styles: { fontStyle: "bold", fillColor: "white" } },
        ],
        [
            { content: t("Total"), styles: { fontStyle: "bold", fillColor: "white" } },
            { content: parseFloat(total).toFixed(2) || "", styles: { fontStyle: "bold", fillColor: "white" } },
        ],
    ]);
    autoTable(doc, {
        head: [[t("treatment"), t("price")]],
        margin: { top: 75 },
        body: body,
    });
    doc.text(`Dr. ${username}`, pageWidth - 60, pageHeight - 70);
    doc.text(`____________`, pageWidth - 60, pageHeight - 60);
    doc.setFillColor(217, 217, 217);
    doc.rect(10, pageHeight - 50, pageWidth - 20, 45, "F");
    doc.addImage("/icons/callIcon.png", "png", 20, pageHeight - 34, 5, 5);
    doc.text(phone || "", 30, pageHeight - 30);
    doc.addImage("/icons/emailIcon.png", "png", pageWidth / 2, pageHeight - 34, 5, 5);
    doc.text(email || "", pageWidth / 2 + 10, pageHeight - 30);
    social_accounts?.map((account, i) => {
        const networkImage =
            account.network === "facebook"
                ? "/icons/facebookIcon.png"
                : account.network === "instagram"
                ? "/icons/instagramIcon.png"
                : null;
        if (i % 2 !== 0) {
            if (networkImage) doc.addImage(networkImage, "png", pageWidth / 2, pageHeight - 20, 5, 5);
            doc.text(`${account?.account || ""}`, pageWidth / 2 + 10, pageHeight - 16);
        } else {
            if (networkImage) doc.addImage(networkImage, "png", 20, pageHeight - 20, 5, 5);
            doc.text(`${account?.account || ""}`, 30, pageHeight - 16);
        }
    });

    // doc.save("invoice");
    const base64 = doc.output("datauristring");
    const newWindow = window.open();
    newWindow.document.write(`<iframe width='100%' height='100%' src='${base64}'></iframe>`);
};
