import { Button, Card, CardActions, CardContent, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { ClientSearch, CurrenciesTotalTable } from "../../components/common";
import { AutoCompleteControl, InputControl, SelectControl } from "../../components/form";
import { useApi } from "../../components/hooks";
import { Modal } from "../../components/modal";
import { EditableTable } from "../../components/table";
import { printInvoice } from "../../components/pdf/invoicePdf";

const rules = {
    patient_id: "required",
    user_id: "required",
};

const InvoiceForm = () => {
    const [values, setValues] = useState({});
    const [doctors, setDoctors] = useState([]);
    const [patientTreatments, setPatientTreatments] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [currenciesValues, setCurrenciesValues] = useState([]);
    const { t } = useTranslation();
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const navigate = useNavigate();

    const { patientId, selectedTreatments, onSaveEffect, addInvoice } = useOutletContext() || {};

    useEffect(() => {
        if (patientId) setValues({ ...values, patient_id: patientId });
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadPatientTreatments();
    }, [values.patient_id]);

    const loadData = async () => {
        const endpoints = [
            {
                endpoint: "users",
                data: { activeOnly: true },
                responseData: "_id name",
            },
            {
                endpoint: "currencies",
                responseData: "_id name code rate",
            },
        ];
        if (params.invoiceId !== "create") {
            endpoints.push({
                endpoint: "invoice",
                data: {
                    _id: "invoices_" + params?.invoiceId,
                },
                responseData: "_id patient_id user_id table_data{treatment price} discount note",
            });
        }
        const response = await fetch({
            operation: "query",
            multipleEndpoints: endpoints,
        });
        if (response?.users) setDoctors(response.users.map((user) => ({ value: user._id, label: user.name })));
        if (response?.invoice) setValues(response.invoice);
        if (response?.currencies) {
            setCurrencies(response?.currencies?.map((c) => ({ value: c.code, label: c.code, rate: c.rate })));
        }
    };

    const loadPatientTreatments = async () => {
        if (!values.patient_id) {
            setPatientTreatments([]);
            return;
        }
        const response = await fetch({
            operation: "query",
            endpoint: "patient",
            data: { _id: values.patient_id },
            responseData: "treatments{_id service_name price}",
        });
        if (response?.patient?.treatments) {
            setPatientTreatments(
                response?.patient?.treatments.map(({ _id, service_name, price }) => ({
                    value: _id,
                    label: service_name,
                    price,
                }))
            );
            if (selectedTreatments && selectedTreatments.length) {
                setValues({
                    ...values,
                    table_data: response.patient.treatments
                        ?.filter((t) => selectedTreatments.includes(t._id))
                        ?.map((t) => ({ treatment: t._id, price: t.price })),
                });
            }
        }
    };

    const cols = [
        {
            headerName: t("treatment"),
            cell: ({ row, rowIndex }) => (
                <SelectControl
                    value={row?.treatment}
                    options={patientTreatments}
                    onChange={(e) =>
                        setValues({
                            ...values,
                            table_data: values?.table_data?.map((el, i) => {
                                if (i === rowIndex) {
                                    const { price } = patientTreatments?.find((t) => t.value === e.target.value) || {};
                                    return {
                                        treatment: e.target.value,
                                        price,
                                    };
                                }
                                return el;
                            }),
                        })
                    }
                    InputProps={{ style: { textTransform: "capitalize" } }}
                />
            ),
        },
        {
            headerName: t("price"),
            cell: ({ row }) => <InputControl isCurrencyInput value={row?.price} />,
        },
    ];

    const calculateTotal = useMemo(() => {
        let totalPrice = values.table_data?.reduce((total, item) => {
            const price = parseFloat(item.price);
            if (!isNaN(price)) total += price;
            return total;
        }, 0.0);
        const discount = parseFloat(values.discount);
        if (!isNaN(discount)) totalPrice -= discount;
        return totalPrice || 0.0;
    }, [values.table_data, values.discount]);

    const calculateEuroRate = useMemo(() => {
        const total = parseFloat(calculateTotal) / 100 / parseFloat(values.euroRate);
        if (isNaN(total)) return "";
        return total?.toFixed(2);
    }, [calculateTotal, values.euroRate]);

    const calculateUSDRate = useMemo(() => {
        const total = parseFloat(calculateTotal) / 100 / parseFloat(values.usdRate);
        if (isNaN(total)) return "";
        return total?.toFixed(2);
    }, [calculateTotal, values.usdRate]);

    const saveHandler = async () => {
        const { patient_id, user_id, table_data = [], note, discount, _id } = values;
        const data = {
            patient_id,
            user_id,
            note,
            discount: !!discount ? parseFloat(discount) : null,
            table_data,
            total: calculateTotal,
        };
        if (_id) data._id = _id;
        const response = await fetch({
            operation: "mutation",
            endpoint: _id ? "updateInvoice" : "createInvoice",
            data,
            responseData:
                "_id created_at total username discount table_data{treatment} note patient_name patient_lastname",
        });
        if (response?.updateInvoice?._id || response?.createInvoice?._id) {
            enqueueSnackbar(t("invoice_saved"), { variant: "default" });
            /** Trigger onSaveEffect (provided by another screen) if another screen is waiting for response */
            if (onSaveEffect) onSaveEffect(_id ? response.updateInvoice : response.createInvoice);

            if (addInvoice) addInvoice(_id ? response.updateInvoice : response.createInvoice);

            navigate(-1);
        }
    };

    const generatePdf = () => {
        if (!values?._id) return;
        try {
            printInvoice({ invoiceId: values._id, fetch, t });
        } catch (err) {
            enqueueSnackbar(t("print_failed"), { variant: "warning" });
        }
    };

    return (
        <div>
            <Modal
                open
                titlelabel={t("invoices")}
                maxWidth="md"
                onClose={() => navigate(-1)}
                FormProps={{
                    values,
                    onValuesChange: setValues,
                    onSubmit: saveHandler,
                    rules,
                }}
                loading={loading}
                permissions={{ invoices: values?._id ? "update" : "create" }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <ClientSearch
                            patientId={values?.patient_id}
                            onPatientSelect={({ value }) => setValues({ ...values, patient_id: value })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <AutoCompleteControl options={doctors} name="user_id" label={t("doctor")} />
                    </Grid>
                    <Grid item xs={12}>
                        <EditableTable
                            variant="outlined"
                            columns={cols}
                            data={values.table_data || []}
                            onAddRow={() => setValues({ ...values, table_data: [...(values.table_data || []), {}] })}
                            onRowDelete={(index) =>
                                setValues({
                                    ...values,
                                    table_data: values.table_data.filter((el, i) => i !== index),
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputControl name="note" label={t("note")} multiline minRows={3} />
                    </Grid>
                    <Grid item xs={6} marginLeft="auto">
                        <Card variant="outlined" sx={{ boxShadow: "none" }}>
                            <CardContent>
                                <InputControl name="discount" label={t("discount")} />
                                <Typography marginTop={2} fontWeight="bold">
                                    {t("total")}:
                                </Typography>
                                <Typography>{calculateTotal?.toFixed(2)} All</Typography>
                            </CardContent>
                            <CardActions>
                                {values?._id && (
                                    <Button onClick={() => generatePdf()} variant="outlined" color="primary">
                                        {t("print")}
                                    </Button>
                                )}
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} marginLeft="auto">
                        <CurrenciesTotalTable
                            currencies={currencies}
                            onChange={setCurrenciesValues}
                            data={currenciesValues}
                            total={calculateTotal}
                        />
                    </Grid>
                </Grid>
            </Modal>
        </div>
    );
};

export default InvoiceForm;
