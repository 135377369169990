import { MenuItem, TextField } from "@mui/material";
import React from "react";

const SelectControl = ({ options = [], name, size = "small", margin = "dense", fullWidth = true, ...otherProps }) => {
    return (
        <TextField select size={size} margin={margin} fullWidth={fullWidth} {...otherProps}>
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value} sx={{ textTransform: "capitalize" }}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default SelectControl;
