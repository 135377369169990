export const convertImageToBase64 = async (imageUrl) => {
    try {
        const response = await fetch(imageUrl, {
            mode: "cors", // Explicitly state we want CORS
        });
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error("Error converting image:", error);
        return null;
    }
};
