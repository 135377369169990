import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputControl } from "../../../components/form";
import UploadComponent from "../../../components/form/UploadComponent";
import { useApi } from "../../../components/hooks";
import { PatientDataContext } from "./PatientDataContext";
import { DateTime } from "luxon";
import { uploadToAws } from "../../../utils/uploadToAWS";
import { ProtectedAccessAction } from "../../../components/common/";
import FolderImage from "../../../assets/images/folder.png";

const DocumentsPanel = () => {
    const { t } = useTranslation();
    const [values, setValues] = useState({ files: [] });
    const [notes, setNotes] = useState([]);
    const [activeFolder, setActiveFolder] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { patientData } = useContext(PatientDataContext);
    const [loading, setLoading] = useState(false);

    const folders = useMemo(() => {
        const uniqueFolders = [];
        notes.forEach((note) => {
            if (!uniqueFolders.includes(note.folder) && !!note.folder) {
                uniqueFolders.push(note.folder);
            }
        });
        return uniqueFolders;
    }, [notes]);

    const { fetch } = useApi();
    useEffect(() => {
        loadNotes();
    }, []);

    const uploadNewNote = async () => {
        const { note, files, folder } = values || {};
        if (!note && !files?.length > 0) {
            enqueueSnackbar(t("provide_note_or_file"), { variant: "warning" });
            return;
        }
        setLoading(true);
        const data = { note, folder: folder || activeFolder || undefined, patient_id: patientData._id };
        if (files?.length > 0) {
            data.files = await uploadToAws(files, fetch);
        }
        const response = await fetch({
            operation: "mutation",
            endpoint: "addPatientNote",
            data,
            responseData: "_id note files folder created_at",
        });
        if (response?.addPatientNote?._id) {
            enqueueSnackbar(t("saved"), { variant: "default" });
            setNotes([...notes, response.addPatientNote]);
            setValues({});
            setLoading(false);
        }
    };

    const loadNotes = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "patientNotes",
            data: { patient_id: patientData?._id },
            responseData: "_id note files folder created_at",
        });
        if (response?.patientNotes) {
            setNotes(response.patientNotes);
            setValues({});
        }
    };
    return (
        <div>
            {!!activeFolder && (
                <Button
                    variant="contained"
                    color="primaryLight"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                        setActiveFolder(null);
                    }}
                >
                    {t("back")}
                </Button>
            )}
            {!!activeFolder ? (
                <Typography>{activeFolder}</Typography>
            ) : (
                <Stack direction="row" gap={4} flexWrap="wrap" alignItems="start">
                    {folders?.map((folder) => {
                        return (
                            <Stack
                                flexWrap="wrap"
                                maxWidth={"100px"}
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                    setActiveFolder(folder);
                                }}
                            >
                                <img src={FolderImage} alt="notes folder" style={{ width: "100px", height: "100px" }} />
                                <Typography>{folder}</Typography>
                            </Stack>
                        );
                    })}
                </Stack>
            )}
            <Stack direction="row" gap={4} flexWrap="wrap" alignItems="start" marginTop={2}>
                <Card sx={{ width: 300, borderRadius: 5 }}>
                    {loading && <LinearProgress color="primary" />}
                    <CardHeader
                        title={t("new")}
                        action={
                            <UploadComponent
                                onFileChange={(e) => {
                                    const files = [];
                                    for (const file of e.target?.files) {
                                        files.push(file);
                                    }
                                    setValues({
                                        ...values,
                                        files,
                                    });
                                }}
                            />
                        }
                    />
                    <CardContent>
                        <InputControl
                            value={values?.note || ""}
                            onChange={(e) => setValues({ ...values, note: e.target.value })}
                            label={t("note")}
                            multiline
                            minRows={4}
                        />
                        <InputControl
                            value={values?.folder || activeFolder || ""}
                            onChange={(e) => setValues({ ...values, folder: e.target.value })}
                            label={t("folder")}
                            multiline
                            minRows={2}
                        />
                        <Stack direction="column" style={{ overflowY: "scroll" }}>
                            {values?.files?.map((file) => (
                                <Typography>{file.name}</Typography>
                            ))}
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <ProtectedAccessAction permissions={{ documents: "create" }}>
                            <Button variant="contained" fullWidth onClick={uploadNewNote}>
                                {t("save")}
                            </Button>
                        </ProtectedAccessAction>
                    </CardActions>
                </Card>
                {notes
                    ?.filter((note) => {
                        if (activeFolder) return note.folder === activeFolder;
                        else return false;
                    })
                    ?.map(({ note, files, created_at }) => (
                        <NoteCard
                            t={t}
                            note={note}
                            files={files}
                            date={DateTime.fromISO(created_at).toFormat("yyyy LLL dd HH:mm:ss")}
                        />
                    ))}
                <Stack direction="row" gap={2}>
                    {notes
                        ?.filter((note) => {
                            if (!note.folder && !activeFolder) return true;
                            return false;
                        })
                        ?.map(({ note, files, created_at }) => (
                            <NoteCard
                                t={t}
                                note={note}
                                files={files}
                                date={DateTime.fromISO(created_at).toFormat("yyyy LLL dd HH:mm:ss")}
                            />
                        ))}
                </Stack>
            </Stack>
        </div>
    );
};

const NoteCard = ({ note, files, t, date }) => {
    const downloadFile = (file) => {
        window.open(file, "_blank");
    };
    return (
        <Card sx={{ width: 300, height: 350, borderRadius: 5 }}>
            <CardHeader title={date} titleTypographyProps={{ variant: "body1" }} />
            <CardContent sx={{ height: 230 }}>
                {note ? <Typography>{note}</Typography> : <Typography textAlign="center">{"no_note"}</Typography>}
                <List sx={{ width: "100%", maxWidth: 360 }} disablePadding>
                    {files?.map((file) => (
                        <ListItem>
                            <ProtectedAccessAction permissions={{ documents: "view" }}>
                                <Button
                                    onClick={(e) => {
                                        downloadFile(file);
                                    }}
                                    variant="contained"
                                    color="primaryLight"
                                >
                                    <DownloadIcon />
                                </Button>
                            </ProtectedAccessAction>
                            <ListItemText
                                style={{
                                    marginLeft: "10px",
                                    width: "70px",
                                    overflowX: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                }}
                                primary={file?.split(".amazonaws.com/")?.[1]}
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default DocumentsPanel;
