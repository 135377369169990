import { Button, Stack } from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PrescriptionModal } from "../../../components/common";
import { PrescriptionCard } from "../../../components/common";
import { useApi } from "../../../components/hooks";
import { PatientDataContext } from "./PatientDataContext";

const PatientPrescription = () => {
    const [openModal, setOpenModal] = useState(false);
    const [values, setValues] = useState({});

    const { patientData, extraData, setPatientData } = useContext(PatientDataContext);
    const { loading, fetch } = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
        if (!patientData?.prescriptions) {
            loadPatientPrescriptions();
        }
    }, []);

    const loadPatientPrescriptions = async () => {
        const response = await fetch({
            operation: "query",
            endpoint: "patientPrescriptions",
            data: {
                patient_id: patientData._id,
            },
            responseData: "_id user_id username created_at drugs{id amount unit description dtdn dtdnUnit} diagnose",
        });
        setPatientData({ ...patientData, prescriptions: response?.patientPrescriptions || [] });
    };

    const savePrescription = async (prescriptionData) => {
        const data = {
            user_id: prescriptionData.user_id,
            patient_id: patientData._id,
            diagnose: prescriptionData.diagnose,
            drugs: prescriptionData?.drugs?.map((drug) => ({
                id: drug.id,
                amount: drug.amount,
                unit: drug.unit,
                description: drug.description,
                dtdn: drug.dtdn,
                dtdnUnit: drug.dtdnUnit,
            })),
        };
        if (prescriptionData?._id) data._id = prescriptionData._id;
        const response = await fetch({
            operation: "mutation",
            endpoint: prescriptionData._id ? "updatePrescription" : "createPrescription",
            data,
            responseData: "_id user_id username created_at drugs{id amount unit description dtdn dtdnUnit} diagnose",
        });
        if (response?.createPrescription?._id || response?.updatePrescription?._id) {
            enqueueSnackbar(t("prescription_saved"), { variant: "default" });
            if (prescriptionData._id) {
                setPatientData({
                    ...patientData,
                    prescriptions: patientData.prescriptions.map((p) => {
                        if (p._id === response.updatePrescription._id) return response.updatePrescription;
                        return p;
                    }),
                });
                return;
            }
            setPatientData({
                ...patientData,
                prescriptions: [...(patientData?.prescriptions || []), response?.createPrescription],
            });
        }
    };

    const removePrescription = (prescriptionId) => {
        setPatientData({
            ...patientData,
            prescriptions: patientData?.prescriptions.filter((p) => p._id !== prescriptionId),
        });
        setOpenModal(false);
        setValues({});
    };

    return (
        <div>
            <Button sx={{ marginBottom: 2 }} variant="contained" color="secondary" onClick={() => setOpenModal(true)}>
                Add Prescription
            </Button>
            <Stack direction="row" gap={2} flexWrap="wrap">
                {patientData?.prescriptions?.map((prescription) => {
                    let drugName = "";
                    prescription?.drugs?.forEach((drug) => {
                        drugName = extraData?.drugs?.find((d) => d._id === drug.id)?.name;
                        drug.name = drugName + ` ${drug.amount} ${drug.unit}`;
                    });
                    return (
                        <PrescriptionCard
                            drugs={prescription?.drugs}
                            dateTime={
                                !!prescription?.created_at
                                    ? DateTime.fromISO(prescription?.created_at).toFormat("yyyy LLL dd HH:mm")
                                    : ""
                            }
                            userName={prescription?.username}
                            onClick={() => {
                                setValues({ ...prescription });
                                setOpenModal(true);
                            }}
                            id={prescription?._id}
                        />
                    );
                })}
            </Stack>
            <PrescriptionModal
                onSave={savePrescription}
                open={openModal}
                data={values}
                removePrescription={removePrescription}
                onClose={() => {
                    setOpenModal(false);
                    setValues({});
                }}
            />
        </div>
    );
};

export default PatientPrescription;
