import jsPDF from "jspdf";
import { DateTime } from "luxon";
import { convertImageToBase64 } from "./convertImageToBase64";

export const printPdf = async ({ prescriptionId, fetch, t }) => {
    const endpoints = [
        {
            endpoint: "settings",
            responseData: `_id email phone social_accounts{network account} logo`,
        },
        {
            endpoint: "prescription",
            responseData: `_id patient_name patient_lastname patient_birthday patient_gender drugs{id amount unit description dtdn dtdnUnit} username diagnose`,
            data: {
                _id: prescriptionId,
            },
        },
        {
            endpoint: "drugs",
            responseData: `_id name`,
        },
    ];
    const response = await fetch({
        operation: "query",
        multipleEndpoints: endpoints,
    });

    const dbDrugs = response?.drugs;
    const { patient_name, patient_lastname, patient_birthday, patient_gender, username, diagnose } =
        response?.prescription || {};
    let { drugs } = response?.prescription || {};

    const { email = "", phone = "", social_accounts, logo } = response?.settings || {};

    const pdfLogo = !!logo
        ? logo
        : "https://scontent.ftia4-1.fna.fbcdn.net/v/t1.6435-9/191778528_182789857069137_3740446214998883965_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=e3f864&_nc_ohc=VuUUY-bSrtQAX9e5uNB&_nc_ht=scontent.ftia4-1.fna&oh=00_AfBRwsUNRCl0cJuA_Mlbw019z39iiE7VI-dl9OmC3x_gYA&oe=6443F60E";
    const base64Image = await convertImageToBase64(pdfLogo);

    drugs = drugs?.map((drug) => {
        const drugName = dbDrugs.find((d) => d._id === drug.id)?.name;
        return {
            ...drug,
            name: drugName,
        };
    });
    let age = "";
    if (!!patient_birthday) {
        const { years } = DateTime.now().diff(DateTime.fromISO(patient_birthday), [t("years")]);
        age = years.toFixed(0) + " " + t("vjeç");
    }
    let gender = "";
    if (patient_gender) {
        if (patient_gender === "male") gender = t("male");
        else gender = t("female");
    }

    const doc = new jsPDF("p", "mm", "a4");
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    let row = 10;
    doc.setFont("", "regular");
    if (base64Image) {
        doc.addImage(base64Image, "png", (pageWidth - 70) / 2, row, 60, 30);
    }
    row += 35;
    doc.setLineWidth(1);
    doc.line(10, row, pageWidth - 10, row);
    row += 10;
    doc.text(`${t("Name_lastname")}: ${patient_name || ""} ${patient_lastname || ""}`, 10, row);
    row += 7;
    doc.text(`${t("Age")}: ${age || ""}`, 10, row);
    row += 7;
    doc.text(`${t("Gender")}: ${gender || ""}`, 10, row);
    row += 7;
    doc.text(`${t("Diagnose")}: ${diagnose || ""}`, 10, row);
    row += 10;
    doc.text(`Rp.:`, 10, row);
    row += 10;
    drugs?.map((drug) => {
        doc.text(
            `${drug?.name} ${drug.amount}${drug.unit}\nD.t.d.N.  ${drug?.dtdn} ${drug?.dtdnUnit}\nS.  ${drug?.description}`,
            20,
            row
        );
        row += 25;
    });
    doc.line(10, pageHeight - 80, pageWidth - 10, pageHeight - 80);
    doc.text(t("Date"), 30, pageHeight - 70);
    doc.text(DateTime.now().toLocaleString(), 30, pageHeight - 60);
    doc.text(`Dr. ${username || ""}`, pageWidth - 60, pageHeight - 70);
    doc.text("_____________", pageWidth - 60, pageHeight - 60);
    doc.setFillColor(217, 217, 217);
    doc.rect(10, pageHeight - 50, pageWidth - 20, 45, "F");
    doc.addImage("/icons/callIcon.png", "png", 20, pageHeight - 34, 5, 5);
    doc.text(phone || "", 30, pageHeight - 30);
    doc.addImage("/icons/emailIcon.png", "png", pageWidth / 2, pageHeight - 34, 5, 5);
    doc.text(email || "", pageWidth / 2 + 10, pageHeight - 30);
    social_accounts?.map((account, i) => {
        const networkImage =
            account.network === "facebook"
                ? "/icons/facebookIcon.png"
                : account.network === "instagram"
                ? "/icons/instagramIcon.png"
                : null;
        if (i % 2 !== 0) {
            if (networkImage) doc.addImage(networkImage, "png", pageWidth / 2, pageHeight - 20, 5, 5);
            doc.text(`${account?.account || ""}`, pageWidth / 2 + 10, pageHeight - 16);
        } else {
            if (networkImage) doc.addImage(networkImage, "png", 20, pageHeight - 20, 5, 5);
            doc.text(`${account?.account || ""}`, 30, pageHeight - 16);
        }
    });
    // doc.save("prescription");
    const base64 = doc.output("datauristring");
    const newWindow = window.open();
    newWindow.document.write(`<iframe width='100%' height='100%' src='${base64}'></iframe>`);
};
